<template>
  <section>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                Collections
                <span class="d-block text-muted pt-2 font-size-lg"
                  >Add collection of where what you want search.</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->

              <v-flex class="mr-2">
                <edit_collection_dialog />
              </v-flex>
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    :headers="headers"
                    :items="collections"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.force_stopwords="{ item }">
                      <span v-if="item.force_stopwords">
                        Forced
                      </span>
                    </template>
                    <template v-slot:item.need_update="{ item }">
                      <span v-if="item.need_update">
                        Yes
                      </span>
                      <span v-else>
                        No
                      </span>
                    </template>
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          placeholder="Search collection"
                          label="Search"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <b-dropdown text="Actions">
                        <b-dropdown-item @click.prevent="editItem(item)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click.prevent="updateItem(item)">
                          Update
                        </b-dropdown-item>
                        <b-dropdown-item @click.prevent="recreateItem(item)">
                          Recreate
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <template v-slot:item.relevant_rate="{ item }">
                      {{ rate_mapping[item.relevant_rate] }}
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  CLOSE_DIALOG,
  PULL_COLLECTION,
  PULL_COLLECTIONS,
  RECREATE_COLLECTION,
  UPDATE_COLLECTION
} from "@/core/services/store/collections.module";
import edit_collection_dialog from "./edit_dialog";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import Swal from "sweetalert2";

export default {
  components: { edit_collection_dialog },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: "Name", value: "name" },
        { text: "Elastic Name", value: "elastic_name" },
        { text: "Item counts", value: "item_counts" },
        { text: "Need recreate", value: "need_update" },
        { text: "Status", value: "status" },
        { text: "Force stopwords", value: "force_stopwords" },
        { text: "Relevant data rate (%)", value: "relevant_rate" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    reloadData() {
      this.$store.dispatch(PULL_COLLECTIONS);
    },
    editItem(item) {
      this.$store.dispatch(RELOAD_MESSAGE);
      this.$store.dispatch(PULL_COLLECTION, item.id);
    },
    recreateItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want recreate this collection \`${item.name}\`? This collection will be deleted and again created, the data must be imported again (this will be automatically setted) Your website (search) may be in a few minutes unavailable. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, recreate it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(RECREATE_COLLECTION, item.id).then(() => {
            this.dialog = false;
            this.$store.dispatch(CLOSE_DIALOG);
            this.$store.dispatch(PULL_COLLECTIONS);
            Swal.fire(
              "Done!",
              `The colletion \`${item.name}\` is created by new configuration, the data will be start imported in a few seconds.`,
              "success"
            );
          });
        }
      });
    },
    updateItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want update this collection \`${item.name}\`? This collection will be updated. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, updated it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(UPDATE_COLLECTION, item.id).then(() => {
            Swal.fire(
              "Done!",
              `The collection \`${item.name}\` is updated.`,
              "success"
            );
            this.dialog = false;
            this.$store.dispatch(CLOSE_DIALOG);
            this.$store.dispatch(PULL_COLLECTIONS);
          });
        }
      });
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.collections.errors,
      message: state => state.collections.message,
      hasMessage: state => state.collections.hasMessage,
      collections: state => state.collections.collections,
      loading: state => state.collections.loading,
      rate_mapping: state => state.collections.relevantMapper
    }),
    ...mapGetters(["currentProject"])
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Collections" }]);
  }
};
</script>
