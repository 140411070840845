<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click.prevent="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
        >New Record</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Add collection</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div
                :class="`alert alert-${message.color}`"
                role="alert"
                v-if="message.show"
              >
                {{ message.text }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="collection.name"
                required
                label="Collection name"
                ref="f_name"
                :rules="[v => !!v || 'Item is required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="collection.id == 0">
            <v-col cols="12">
              <v-select
                v-model="predefined"
                label="Dependent by"
                ref="f_collection_predefined"
                item-value="name"
                item-text="name"
                item-key="id"
                return-object
                :items="collections_predefined"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="collection.force_stopwords"
                label="Force stopwords"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="collection.relevant_rate"
                ref="f_collection_rates"
                item-value="value"
                item-text="name"
                item-key="id"
                label="Relevant data rate"
                hint="The higher the rate, the more data the query will display"
                return-object
                :items="rates"
                required
              ></v-select>
            </v-col>
          </v-row>

          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color=" darken-1" text @click="showDialog = false"
            >Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="collection.id > 0"
            color="error dark large"
            ref="submit"
            @click="deleteItem"
            >Delete
          </v-btn>
          <v-btn
            v-if="collection.id > 0"
            color="error dark large"
            ref="submit"
            @click="recreateItem"
            >Recreate
          </v-btn>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  PULL_COLLECTION,
  REFRESH,
  PULL_COLLECTIONS,
  PULL_COLLECTIONS_PREDEFINED,
  PUT_COLLECTION,
  POST_COLLECTION,
  CLOSE_DIALOG,
  DELETE_COLLECTION,
  RECREATE_COLLECTION
} from "@/core/services/store/collections.module";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";

export default {
  props: {
    itemId: {
      type: Number
    }
  },
  data: () => ({
    valid: true,
    dialog: false,
    rates: [
      { name: "OFF", value: 0 },
      { name: "10%", value: 10 },
      { name: "20%", value: 20 },
      { name: "30%", value: 30 },
      { name: "40%", value: 40 },
      { name: "50%", value: 50 },
      { name: "60%", value: 60 },
      { name: "70%", value: 70 },
      { name: "80%", value: 80 },
      { name: "90%", value: 90 },
      { name: "ALL", value: 100 }
    ],
    predefined: {
      id: "6",
      name: "Custom",
      description: null,
      schema: null
    },
    term: ""
  }),
  methods: {
    refresh: function() {
      this.term = "";
    },
    close() {
      this.dialog = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.collection.id === 0) {
          this.$store
            .dispatch(POST_COLLECTION, {
              name: this.$refs.f_name.value,
              type_id: this.$refs.f_collection_predefined.value.id,
              force_stopwords: this.collection.force_stopwords,
              relevant_rate: this.collection.relevant_rate.value
            })
            .then(() => {
              this.dialog = false;
              this.$store.dispatch(CLOSE_DIALOG);
              this.$store.dispatch(PULL_COLLECTIONS);
            });
        } else {
          this.$store
            .dispatch(PUT_COLLECTION, {
              id: this.collection.id,
              data: {
                name: this.$refs.f_name.value,
                force_stopwords: this.collection.force_stopwords,
                relevant_rate: this.collection.relevant_rate.value
              }
            })
            .then(() => {
              this.dialog = false;
              this.$store.dispatch(CLOSE_DIALOG);
              this.$store.dispatch(PULL_COLLECTIONS);
            });
        }
      }
    },
    deleteItem() {
      this.$store.dispatch(DELETE_COLLECTION, this.collection.id).then(() => {
        this.dialog = false;
        this.$store.dispatch(CLOSE_DIALOG);
        this.$store.dispatch(PULL_COLLECTIONS);
      });
    },
    recreateItem() {
      this.$store.dispatch(RECREATE_COLLECTION, this.collection.id).then(() => {
        this.dialog = false;
        this.$store.dispatch(CLOSE_DIALOG);
        this.$store.dispatch(PULL_COLLECTIONS);
      });
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    showDialog: {
      get: function() {
        return this.dialog || this.show_dialog;
      },
      set: function(value) {
        this.dialog = value;
        this.$store.dispatch(CLOSE_DIALOG);
      }
    },
    ...mapState({
      errors: state => state.collections.errors,
      message: state => state.message.message,
      collection: state => state.collections.collection,
      collections_predefined: state => state.collections.collections_predefined,
      show_dialog: state => state.collections.showDialog
    })
  },
  beforeCreate() {
    this.$store.dispatch(PULL_COLLECTIONS_PREDEFINED);
    this.$store.dispatch(RELOAD_MESSAGE);
    this.$store.dispatch(REFRESH);
  },
  created() {
    if (this.itemId > 0) {
      this.$store.dispatch(PULL_COLLECTION, this.itemId);
    }
  }
};
</script>
