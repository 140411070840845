var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header flex-wrap py-3"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[_c('v-flex',{staticClass:"mr-2"},[_c('edit_collection_dialog')],1)],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"dataTables_wrapper dt-bootstrap4 no-footer",attrs:{"id":"kt_datatable_wrapper"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('v-data-table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",attrs:{"headers":_vm.headers,"items":_vm.collections,"options":_vm.options,"loading":_vm.loading,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.force_stopwords",fn:function(ref){
var item = ref.item;
return [(item.force_stopwords)?_c('span',[_vm._v(" Forced ")]):_vm._e()]}},{key:"item.need_update",fn:function(ref){
var item = ref.item;
return [(item.need_update)?_c('span',[_vm._v(" Yes ")]):_c('span',[_vm._v(" No ")])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center position-relative my-1"},[_c('span',{staticClass:"svg-icon svg-icon-1 position-absolute ms-6"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('rect',{attrs:{"opacity":"0.5","x":"17.0365","y":"15.1223","width":"8.15546","height":"2","rx":"1","transform":"rotate(45 17.0365 15.1223)","fill":"black"}}),_c('path',{attrs:{"d":"M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z","fill":"black"}})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-solid w-250px ps-14",attrs:{"type":"text","placeholder":"Search collection","label":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"text":"Actions"}},[_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" Edit ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.updateItem(item)}}},[_vm._v(" Update ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.recreateItem(item)}}},[_vm._v(" Recreate ")])],1)]}},{key:"item.relevant_rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.rate_mapping[item.relevant_rate])+" ")]}}])})],1)])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Collections "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-lg"},[_vm._v("Add collection of where what you want search.")])])])}]

export { render, staticRenderFns }